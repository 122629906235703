@import "../../App.scss";

.nav-bar {
  background: #181818;
  // width: 10%;
  width: 60px;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 3;
  min-height: 500px;
  .logo {
    display: block;
    padding: 8px 0;
    img {
      display: block;
      margin: 5px 0, 5px auto;
      width: 50px;
      height: auto;
      border-style: inset;
      border-color: #908cde;
      border-width: 5px;
      border-radius: 10px;
    }
  }
  .subtitle {
    color: rgb(223, 226, 255);
  }

  nav {
    display: block;
    text-align: center;
    position: absolute;
    height: 210px;
    top: 50%;
    margin-top: -120px;
    width: 100%;

    a {
      font-size: 22px; // Icons
      color: #4d4d4e;
      display: block;
      line-height: 51px;
      height: 51px;
      position: relative;
      // text-decoration: none;

      i {
        transition: all 0.3s ease-out;
      }
      &:hover {
        color: $highlight-blue;
        svg {
          opacity: 0;
        }
        &:after {
          opacity: 1;
        }
      }
      &:after {
        content: "";
        font-size: 13px; // icon text size
        font-weight: 400;
        letter-spacing: 2px;
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        text-align: center;
        opacity: 0; // so we get smoooth transition
        transition: all 0.3s ease-out;
      }

      &:first-child {
        &::after {
          content: "Home";
        }
      }
    }
    a.about-link {
      &:after {
        content: "About";
      }
    }

    a.contact-link {
      &:after {
        content: "Contact";
      }
    }

    a.active {
      svg {
        color: $highlight-blue; // highlights current active class eg home icon
      }
    }
  }

  ul {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: block;
    padding: 0;
    list-style: none;
    text-align: center;
    margin: 0;

    li {
      a {
        padding: 7px 0;
        display: block;
        font-size: 25px;
        line-height: 16px;
        &:hover{
          svg {
            color: $highlight-blue;
          }
          color: $highlight-blue; // bottom icon hover colours
        }
      }
    }
  }
}
