@import "../../App.scss";

.home-page {
  .text-zone {
    position: absolute;
    left: 10%; // push away from side bar
    top: 50%; // centre
    transform: translateY(-50%); // make it dead centre, move ontop of 50%
    width: 40%; // 60% elements on other side
    max-height: 90%;
  }
  h1.default-text {
    color: white;
    font-size: 53px;
    margin: 0;
    // font-family: 'coolvetica';
    // font-weight: 400;
    &::before {
      // ::before is a Pseudo element, first child of the selected element
      content: "<h1>";
      font-family: "La Belle Aurore";
      color: $default-yellow;
      font-size: 18px;
      position: absolute;
      margin-top: -40px;
      left: 15px;
      opacity: 0.6;
    }

    &::after {
      content: "</h1>";
      font-family: "La Belle Aurore";
      color: $default-yellow;
      font-size: 18px;
      position: absolute;
      margin-top: 18px;
      margin-left: 20px;
      animation: fadeIn 1s 1.7s backwards;
      opacity: 0.6;
    }

    h1 {
      // JORDAN
      color: $highlight-blue;
      margin-top: 0;
      margin-bottom: 0;
      // opacity: 0;animate__rotateIn;
      height: auto;
      // animation: rubberBand 1s linear both;
      animation: fadeIn 1s linear both;
      animation-delay: 1.4s;
    }
  }
}

h2 {
  color: $default-grey;
  margin-top: 20px;
  // font-size: 15px;
  font-family: sans-serif;
  letter-spacing: 3px;
  animation: fadeIn 1s 1.8s backwards;
}
.flat-button {
  color: $highlight-blue;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 4px;
  font-family: sans-serif;
  text-decoration: none; // removes blue/purple link color
  padding: 10px 18px;
  border: 1px solid $highlight-blue;
  margin-top: 25px;
  float: left;
  animation: fadeIn 1s 1.8s backwards;
  white-space: normal;//nowrap;
  &:hover{
    background-color: $highlight-blue;
    color: white
  }
}
